import vuetify from '@/plugins/vuetify';
import { Enums } from '@flightscope/baseball-stats';
import { conversionType } from '@/plugins/converter';
import { ChartTypeEnum } from '@/components/ui/charts/ChartHelpers';
import {
  strikeZonePropsProvider,
  strikeZonePropsGuard,
  strikeZoneSeriesMapper,
  strikeZoneSeriesProvider,
} from '@/components/ui/charts/strikeZoneHelpers';
import { ChartTypes } from './ChartTypes';
import { Markers } from './theme';

const ReportsEnum = {
  'pitching-release-point': {
    chartType: ChartTypes.HEATMAP,
    axisContext: ChartTypeEnum.RELEASE_POINT.type,
    convertDimensions: [
      {
        dimension: Enums.SeriesDimension.X,
        conversionType: conversionType.DISTANCE_SHORT,
      },
      {
        dimension: Enums.SeriesDimension.Y,
        conversionType: conversionType.DISTANCE_SHORT,
      },
    ],
    title: vuetify.framework.lang.t('$vuetify.charts.heatmaps.releasePoint'),
    axis: {
      xaxis: {
        title: vuetify.framework.lang.t('$vuetify.horizontalLocation'),
      },
      yaxis: {
        title: vuetify.framework.lang.t('$vuetify.verticalLocation'),
      },
    },
  },

  'pitching-release-extension': {
    chartType: ChartTypes.HEATMAP,
    axisContext: ChartTypeEnum.RELEASE_EXTENSION.type,
    convertDimensions: [
      {
        dimension: Enums.SeriesDimension.X,
        conversionType: conversionType.DISTANCE_SHORT,
        conversionPrecision: 1,
      },
      {
        dimension: Enums.SeriesDimension.Y,
        conversionType: conversionType.DISTANCE_SHORT,
      },
    ],
    title: vuetify.framework.lang.t('$vuetify.charts.heatmaps.releaseExtension'),
    axis: {
      xaxis: {
        title: vuetify.framework.lang.t('$vuetify.horizontalLocation'),
      },
      yaxis: {
        title: vuetify.framework.lang.t('$vuetify.verticalLocation'),
      },
    },
  },

  'pitching-pitch-location': {
    chartType: ChartTypes.HEATMAP,
    axisContext: ChartTypeEnum.PITCH_LOCATION.type,
    convertDimensions: [
      {
        dimension: Enums.SeriesDimension.X,
        conversionType: conversionType.DISTANCE_EXTRA_SHORT,
      },
      {
        dimension: Enums.SeriesDimension.Y,
        conversionType: conversionType.DISTANCE_EXTRA_SHORT,
      },
    ],
    title: vuetify.framework.lang.t('$vuetify.charts.heatmaps.pitchLocation'),
    axis: {
      xaxis: {
        title: vuetify.framework.lang.t('$vuetify.horizontalLocation'),
      },
      yaxis: {
        title: vuetify.framework.lang.t('$vuetify.verticalLocation'),
      },
    },
  },

  'pitching-pitch-movement': {
    chartType: ChartTypes.HEATMAP,
    axisContext: ChartTypeEnum.PITCH_MOVEMENT.type,
    convertDimensions: [
      {
        dimension: Enums.SeriesDimension.X,
        conversionType: conversionType.DISTANCE_EXTRA_SHORT,
      },
      {
        dimension: Enums.SeriesDimension.Y,
        conversionType: conversionType.DISTANCE_EXTRA_SHORT,
      },
    ],
    title: vuetify.framework.lang.t('$vuetify.charts.heatmaps.pitchMovement'),
    axis: {
      xaxis: {
        title: vuetify.framework.lang.t('$vuetify.horizontalMovement'),
      },
      yaxis: {
        title: vuetify.framework.lang.t('$vuetify.verticalMovement'),
      },
    },
  },

  'pitching-pitchers-performance': {
    chartType: ChartTypes.BAR,
    axisContext: ChartTypeEnum.RELEASE_POINT.type,
    convertDimensions: [
      {
        dimension: Enums.SeriesDimension.X,
      },
    ],
    title: vuetify.framework.lang.t('$vuetify.Performance'),
    marker: [Markers.primaryMarker, Markers.secondaryMarker],
    baseOnParams: [
      // Enums.PitchData.BreakVInd.key,
      Enums.PitchData.BreakV.key,
      Enums.PitchData.BreakH.key,
      Enums.PitchData.Spin.key,
      Enums.PitchData.Speed.key,
    ],
  },

  'pitching-pitchers-repeatability': {
    chartType: ChartTypes.BAR,
    axisContext: ChartTypeEnum.RELEASE_POINT.type,
    disableConverting: true,
    convertDimensions: [
      {
        dimension: Enums.SeriesDimension.X,
      },
    ],
    title: vuetify.framework.lang.t('$vuetify.Repeatability'),
    marker: [Markers.primaryMarker],
    baseOnParams: [
      Enums.PitchData.SpinDirection.key,
      Enums.PitchData.Extension.key,
      Enums.PitchData.ReleasePointZ.key,
      Enums.PitchData.ReleasePointX.key,
      Enums.PitchData.BreakVInd.key,
      Enums.PitchData.BreakH.key,
      Enums.PitchData.Spin.key,
      Enums.PitchData.Speed.key,
    ],
  },

  'pitching-plate-discipline': {
    chartType: ChartTypes.BAR,
    disableConverting: true,
    convertDimensions: [
      {
        dimension: Enums.SeriesDimension.X,
      },
    ],
    title: vuetify.framework.lang.t('$vuetify.PlateDiscipline'),
    marker: [Markers.primaryMarker, Markers.secondaryMarker],
    baseOnParams: [
      Enums.BattingStats.KZoneRate.key,
      Enums.BattingStats.StrikeoutRate.key,
      Enums.BattingStats.SwingRate.key,
      Enums.BattingStats.WhiffRate.key,
      Enums.BattingStats.SwingingStrikeRate.key,
      Enums.BattingStats.ZSwingRate.key,
      Enums.BattingStats.ZContactRate.key,
      Enums.BattingStats.OSwingRate.key,
      Enums.BattingStats.OContactRate.key,
    ],
  },

  'batting-batters-performance': {
    chartType: ChartTypes.BAR,
    convertDimensions: [
      {
        dimension: Enums.SeriesDimension.X,
      },
    ],
    title: vuetify.framework.lang.t('$vuetify.Performance'),
    marker: [Markers.primaryMarker, Markers.secondaryMarker],
    baseOnParams: [
      Enums.HitData.ExitSpeed.key,
      Enums.HitData.LaunchV.key,
      Enums.HitData.CarryDistance.key,
      // Enums.BattingStats.HardHitRate.key, // BBP-1161 - hide for now
    ],
  },

  'batting-plate-discipline': {
    chartType: ChartTypes.BAR,
    disableConverting: true,
    convertDimensions: [
      {
        dimension: Enums.SeriesDimension.X,
      },
    ],
    title: vuetify.framework.lang.t('$vuetify.PlateDiscipline'),
    marker: [Markers.primaryMarker, Markers.secondaryMarker],
    baseOnParams: [
      Enums.BattingStats.KZoneRate.key,
      Enums.BattingStats.StrikeoutRate.key,
      Enums.BattingStats.SwingRate.key,
      Enums.BattingStats.WhiffRate.key,
      Enums.BattingStats.SwingingStrikeRate.key,
      Enums.BattingStats.ZSwingRate.key,
      Enums.BattingStats.ZContactRate.key,
      Enums.BattingStats.OSwingRate.key,
      Enums.BattingStats.OContactRate.key,
    ],
  },

  'batting-strike-zone': {
    chartType: ChartTypes.HEATMAP,
    axisContext: ChartTypeEnum.STRIKE_ZONE.type,
    convertDimensions: [
      {
        dimension: Enums.SeriesDimension.X,
        conversionType: conversionType.DISTANCE_EXTRA_SHORT,
      },
      {
        dimension: Enums.SeriesDimension.Y,
        conversionType: conversionType.DISTANCE_EXTRA_SHORT,
      },
    ],
    title: vuetify.framework.lang.t('$vuetify.charts.heatmaps.strikeZone'),
    axis: {
      xaxis: {
        title: vuetify.framework.lang.t('$vuetify.horizontalLocation'),
      },
      yaxis: {
        title: vuetify.framework.lang.t('$vuetify.verticalLocation'),
      },
    },
  },

  'batting-strike-zone-2': {
    chartType: ChartTypes.STRIKE_ZONE,
    axisContext: ChartTypeEnum.STRIKE_ZONE.type,
    disableConverting: true,
    convertDimensions: [],
    title: vuetify.framework.lang.t('$vuetify.charts.heatmaps.strikeZone'),
    description: '',
    overloads: {
      seriesProvider: strikeZoneSeriesProvider,
      seriesMapper: strikeZoneSeriesMapper,
      propsGuard: strikeZonePropsGuard,
      propsProvider: strikeZonePropsProvider,
    },
  },

  'pitching-pitch-location-2': {
    chartType: ChartTypes.STRIKE_ZONE,
    axisContext: ChartTypeEnum.STRIKE_ZONE.type,
    disableConverting: true,
    convertDimensions: [],
    title: vuetify.framework.lang.t('$vuetify.charts.heatmaps.pitchLocation'),
    description: '',
    overloads: {
      seriesProvider: strikeZoneSeriesProvider,
      seriesMapper: strikeZoneSeriesMapper,
      propsGuard: strikeZonePropsGuard,
      propsProvider: strikeZonePropsProvider,
    },
  },

  'batting-spray-chart': {
    chartType: ChartTypes.SCATTER,
    axisContext: ChartTypeEnum.SPRAY_CHART.type,
    convertDimensions: [
      {
        dimension: Enums.SeriesDimension.X,
        conversionType: conversionType.DISTANCE_SHORT,
      },
      {
        dimension: Enums.SeriesDimension.Y,
        conversionType: conversionType.DISTANCE_SHORT,
      },
    ],
    title: vuetify.framework.lang.t('$vuetify.reports.sprayChart'),
    axis: {
      xaxis: {
        title: vuetify.framework.lang.t('$vuetify.landingHorizontal'),
      },
      yaxis: {
        title: vuetify.framework.lang.t('$vuetify.landingVertical'),
      },
    },
    hover: 'all',
    enabledHover: true,
  },

  'pitching-performance-tracking': {
    chartType: ChartTypes.TRACKING,
    axisContext: ChartTypeEnum.PITCHER_PERFORMANCE.type,
    dynamicAxis: Enums.SeriesDimension.Y,
    enabledHover: true,
    convertDimensions: [
      {
        dimension: Enums.SeriesDimension.X,
        conversionType: conversionType.NUMBER,
      },
      {
        dimension: Enums.SeriesDimension.Y,
        dataType: Enums.PitchData.Speed,
        baseOn: 'pitchParameter',
      },
    ],
    title: vuetify.framework.lang.t('Tracking Chart'),
    axis: {
      xaxis: {
        title: vuetify.framework.lang.t('Pitch Number'),
      },
    },
    enabledHover: true,
  },

  'batting-performance-tracking': {
    chartType: ChartTypes.TRACKING,
    axisContext: ChartTypeEnum.BATTER_PERFORMANCE.type,
    dynamicAxis: Enums.SeriesDimension.Y,
    convertDimensions: [
      {
        dimension: Enums.SeriesDimension.X,
        conversionType: conversionType.NUMBER,
      },
      {
        dimension: Enums.SeriesDimension.Y,
        dataType: Enums.HitData.ExitSpeed,
        baseOn: 'hitParameter',
      },
    ],
    title: vuetify.framework.lang.t('Tracking Chart'),
    axis: {
      xaxis: {
        title: vuetify.framework.lang.t('Plate Appearance'),
      },
    },
    enabledHover: true,
  },
};

export { ReportsEnum };
