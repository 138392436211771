import Vue from 'vue';
import { Entities, Formatters, Providers } from '@flightscope/baseball-stats';
import { formatUtc, utcToLocal } from '@/utils/date_n_time';
import { convertUnitNoSymbol } from '../filters/units';

const baseballProcessor = new Entities.BaseballResultsProcessor();

const getNameFromEnum = (item, header, forExport) => {
  let value = baseballProcessor.getObjectContent(item, header.path);
  if (value != null && header.nameFromEnum && !forExport && typeof value !== 'undefined') {
    let enumDef = Providers.EnumValueProvider.getValue(value, header.nameFromEnum, header.DefaultIfEmpty);
    value = enumDef.name;
  }
  return value;
};

export const playerDetails = (PlayerTag) => {
  if (!PlayerTag) {
    return '-';
  }
  return `${PlayerTag.FormattedNameWithJersey}`;
};

// eslint-disable-next-line no-unused-vars
const formatCellDateTime = (item, header, index) => {
  let value = baseballProcessor.getObjectContent(item, header.path);

  if (header.utc) {
    return formatUtc(value, header.format);
  }

  return utcToLocal(value, header.format);
};

const convertValueWithoutSymbol = (value, type, unitSystem, precision) => {
  const roundingPrecision = typeof (precision) === 'undefined' ? 2 : precision;
  return convertUnitNoSymbol(value, type, unitSystem, { noValueFallback: '-', fractionalDigits: roundingPrecision });
};

const convertStringifiedCellValue = (item, header, index, delimiter, unitsSystem, forExport) => {
  let value = baseballProcessor.getObjectContent(item, header.path);

  let extracted = Formatters.StringifiedParameterFormatter(
    value, delimiter, header.property.stringifiedAs, parseFloat,
  );
  if (!extracted || extracted.length === 0) {
    let unitArr = [];
    unitArr.length = header.property.stringifiedAs.length;
    extracted = unitArr.fill(undefined, 0, header.property.stringifiedAs.length);
  }

  let stringify = [];
  for (let i = 0; i < header.property.stringifiedAs.length; i++) {
    const property = header.property.stringifiedAs[i];
    const headerType = (forExport === true && header.exportType)
      ? header.exportType : header.property.type;
    stringify.push(
      convertValueWithoutSymbol(extracted[property], headerType, unitsSystem, header.precision),
    );
  }
  return stringify.join(`${delimiter} `);
};

const convertCellValue = (item, header, index, unitsSystem, forExport) => {
  if (header.property && header.property.stringifiedAs) {
    return convertStringifiedCellValue(item, header, index, ';', unitsSystem, forExport);
  }

  let value = baseballProcessor.getObjectContent(item, header.path);
  let headerType;
  if (forExport && header.exportType) {
    headerType = header.exportType;
  } else if (header.hasOwnProperty('property') && header.property) {
    headerType = header.property.type;
  }

  if (!headerType) {
    Vue.$log.debug(`Unable to match header type for ${JSON.stringify(header)}`);
    return null;
  }

  return convertValueWithoutSymbol(value, headerType, unitsSystem, header.precision);
};

const formatSingleCell = (item, header, index, unitsSystem, forExport) => {
  switch (header.type) {
    case 'STRING':
    case undefined:
      return baseballProcessor.getObjectContent(item, header.path) || '-';
    case 'PLAYERNAME':
      return playerDetails(baseballProcessor.getObjectContent(item, header.path));
    case 'DATETIME':
      return formatCellDateTime(item, header, index);
    case 'ENUM':
      return getNameFromEnum(item, header, forExport) || '-';
    case 'OBJECT':
      return null;
    default:
      return convertCellValue(item, header, index, unitsSystem, forExport);
  }
};

export default formatSingleCell;
