import { ExportToCsv } from 'export-to-csv';
// TODO make separate DataRowExport.js instead forExport arg in formatSingleCell
import moment from 'moment';
import formatSingleCell from './DataRow';
import Session from '../models/orm/Session';

export const BASE_CSV_OPTIONS = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: false,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
};

export const DATE_FM = {
  YEAR: 'YYYY',
  DATETIME: 'YYYY-MM-DD',
};

const mergeIntoCSVRecords = (aHeaders, aResults, unitsSystem) => {
  // TODO move into external helper class
  let aCSV = [];
  for (let resultIndex = 0; resultIndex < aResults.length; resultIndex++) {
    let record = {};
    for (let headerIndex = 0; headerIndex < aHeaders.length; headerIndex++) {
      if(aHeaders[headerIndex].preventExport === true) continue;
      const headerLabel = aHeaders[headerIndex].exportName || aHeaders[headerIndex].text;
      const item = aResults[resultIndex];
      record[headerLabel] = formatSingleCell(item, aHeaders[headerIndex], headerIndex, unitsSystem, true);
    }
    aCSV.push(record);
  }
  return aCSV;
};

/**
 *
 * @param {*} data
 * @param {string} filename
 */
export const baseExportCsv = (data, filename = '') => {
  const options = {
    ...BASE_CSV_OPTIONS,
    filename,
  }

  const csvData = data;
  const csvExporter = new ExportToCsv(options);
  return csvExporter.generateCsv(csvData);
};

/**
 *
 * @param {Session} session
 * @param {string} unitsSystem
 */
export const sessionFilename = (session, unitsSystem='') => {
  const date = moment(session.CreateDate);
  const filename = `${session.SessionID}-${session.SessionType}-${date.format(DATE_FM.DATETIME)}-${session.DisplayName.replace(/[^a-z0-9]/i, '_')}-${unitsSystem}`;
  return filename;
};

/**
 *
 * @param {*} headers
 * @param {*} results
 * @param {*} unitsSystem
 * @param {Session} sessionData
 */
const exportCSV = (headers, results, unitsSystem, sessionData) => {
  // merge into single row csv records
  const csvData = mergeIntoCSVRecords(headers, results, unitsSystem);
  const options = {
    ...BASE_CSV_OPTIONS,
    // title: `${sessionData.SessionID}-${sessionData.SessionType}`,
    filename: sessionFilename(sessionData, unitsSystem),
  };

  const csvExporter = new ExportToCsv(options);
  csvExporter.generateCsv(csvData);

  return csvData;
};

export default exportCSV;
