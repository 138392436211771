export default {
  name: 'PlayerProfileLink',

  props: {
    id: {
      type: Number,
    },
    context: {
      type: String,
      required: true,
    },
    reportType: {
      type: String,
      default: 'summary',
    }
  }
};
