import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import playerNameFilter from '@/filters/playerNameFilter';

function defaultPlayerProfile(id) {
  return {
    UId: `placeholder-${id}`,
    Id: id,
    Type: 'placeholder',
    Avatar: process.env.VUE_APP_DEFAULT_PLAYER_AVATAR,
    FirstName: '',
    LastName: '-',
    DisplayName: '-',
    FormattedName: `unknown #${id}`,
    JerseyNumber: '',
    Position: ['UN'],
    Bats: '',
    Throws: '',
    BirthDay: '',
    Height: NaN,
    Weight: NaN,
    School: '-',
    GraduationYear: '-',
    Address: '-',
  };
}

function wsPlayerProfileToPlayerModel(tagData, playerProfile = defaultPlayerProfile(tagData.TagID)) {
  playerProfile.Id = parseInt(tagData.TagID, 10);
  playerProfile.Type = 'tag';
  playerProfile.Avatar = tagData.AvatarURL || playerProfile.Avatar;
  playerProfile.LastName = tagData.LastName || playerProfile.LastName;
  playerProfile.FirstName = tagData.FirstName || playerProfile.FirstName;
  playerProfile.DisplayName = tagData.DisplayName || playerProfile.DisplayName;
  playerProfile.FormattedName = playerNameFilter(process.env.VUE_APP_PLAYER_NAME_FORMAT, playerProfile);
  playerProfile.JerseyNumber = tagData.Number || playerProfile.JerseyNumber || '';
  playerProfile.Position = tagData.Position || playerProfile.Position;
  playerProfile.Bats = tagData.BatHandedness || playerProfile.Bats;
  playerProfile.Throws = tagData.ThrowHandedness || playerProfile.Throws;
  playerProfile.BirthDay = tagData.BirthDate || playerProfile.BirthDay;
  playerProfile.School = tagData.School || playerProfile.School;
  playerProfile.GraduationYear = tagData.GraduationYear || playerProfile.GraduationYear;
  playerProfile.Address = tagData.Address || playerProfile.Address;
  playerProfile.Height = parseFloat(tagData.Height || playerProfile.Height) || NaN;
  playerProfile.Weight = parseFloat(tagData.Weight || playerProfile.Weight) || NaN;

  return playerProfile;
}

export class PlayerModel {
  constructor({
    Avatar,
    Bats,
    DisplayName,
    FirstName,
    FormattedName,
    Height,
    BirthDay,
    Id,
    JerseyNumber,
    LastName,
    Position,
    Throws,
    Type,
    UId,
    Weight,
    Organization,
    School,
    Bio,
    GraduationYear,
    Address
  }, teams, RosterPlayers) {
    this.Avatar = Avatar;
    this.Bats = Bats;
    this.DisplayName = DisplayName;
    this.FirstName = FirstName;
    this.FormattedName = FormattedName;
    this.Height = Height;
    this.Id = Id;
    this.JerseyNumber = JerseyNumber;
    this.LastName = LastName;
    this.Position = Position;
    this.Throws = Throws;
    this.Type = Type;
    this.UId = UId;
    this.Weight = Weight;
    this.School = School;
    this.BirthDay = BirthDay;
    this.GraduationYear = GraduationYear;
    this.Address = Address;

    this.teams = teams || [];
    this.RosterPlayers = RosterPlayers || [];
  }

  get id () {
    return this.Id;
  }

  get Number() {
    const jerseyNumber = this.JerseyNumber;
    if (!this.hasRosterPlayers || !this.recentRosterPlayer || !this.recentRosterPlayer.number || this.recentRosterPlayer.number === '-') {
      return jerseyNumber || '';
    }

    return jerseyNumber || this.recentRosterPlayer.number || '';
  }

  get BirthDate() {
    return this.BirthDay;
  }

  get PrimaryPosition() {
    let tagPosition;
    if (this.Position) {
      if (Array.isArray(this.Position)) {
        tagPosition = this.Position[0];
      }
      else if(typeof (this.Position) === 'string') {
        tagPosition = this.Position;
      }
    }

    if (!this.hasRosterPlayers || !this.recentRosterPlayer || !Array.isArray(this.recentRosterPlayer?.position) || this.recentRosterPlayer.position.length < 1) {
      return tagPosition || 'UI';
    }

    return tagPosition || this.recentRosterPlayer.position[0] || 'UI';
  }

  get SecondaryPosition() {
    let tagSecondaryPosition;
    if (this.Position && Array.isArray(this.Position)) {
      tagSecondaryPosition = this.Position[1];
    }

    if (!this.hasRosterPlayers || !this.recentRosterPlayer || !Array.isArray(this.recentRosterPlayer?.position) || this.recentRosterPlayer.position.length < 2) {
      return tagSecondaryPosition || 'UI';
    }

    return tagSecondaryPosition || this.recentRosterPlayer.position[1] || 'UI';
  }

  get hasTeam() {
    return this.teams.length > 0;
  }

  get hasRosterPlayers() {
    return this.RosterPlayers.length > 0 && this.RosterPlayers.some(p => !p.isDeleted);
  }

  get recentRosterPlayer() {
    return this.RosterPlayers.find(p => !p.isDeleted);
  }

  get team() {
    if (this.hasTeam) {
      let temp = this.teams[0];
      return {
        id: temp.TeamID,
        name: temp.TeamDisplayName,
        logo: temp.TeamLogo,
      };
    }

    return {
      id: '-',
      name: '-',
      logo: '',
    };
  }

  get seasons() {
    if (this.hasTeam) {
      return uniq(flatten(
        this.teams
          .filter(team => team.TeamInSessions.length)
          .map(team => team.TeamInSessions)
      ).map(team => team.Season));
    }
    return [];
  }

  get playedSessions() {
    if (this.hasTeam) {
      return this.teams.reduce(
        (acc, curr) => (Array.isArray(acc) ? [...acc, ...curr.TeamInSessions.filter(team => team.ResultTypes)] : [...acc.TeamInSessions, ...curr.TeamInSessions]),
        { TeamInSessions: [] },
      );
    }
    return [];
  }

  get nonPitchingSessions() {
    return this.playedSessions.filter((sess) => {
      const types = sess.ResultTypes.split(',');
      return types.includes('H') || types.includes('PH');
    });
  }

  get nonBattingSessions() {
    return this.playedSessions.filter((sess) => {
      const types = sess.ResultTypes.split(',');
      return types.includes('P') || types.includes('PH');
    });
  }

  get nonPitchingPracticeDates() {
    return uniq(this.nonPitchingSessions.map((sess) => sess.SessionCreateDate));
  }

  get nonBattingPracticeDates() {
    return uniq(this.nonBattingSessions.map((sess) => sess.SessionCreateDate));
  }

  get Place() {
    if (this.Address && this.Address != '-') {
      return this.Address;
    }

    return [this.City, this.Country].filter(val => val).join(', ') || '-';
  }
}

function fromJSON({ Tag: playerTag, SessionEventTeams: teams, RosterPlayers: rosterPlayers } = {}) {
  let playerData = { ...defaultPlayerProfile(playerTag.TagID) };

  if (playerTag) {
    playerData = wsPlayerProfileToPlayerModel(playerTag, playerData);
  }

  if (teams && Array.isArray(teams) && !rosterPlayers) {
    rosterPlayers = teams.map(t => t.RosterPlayerBase).filter(rp => !!rp);
  }

  return new PlayerModel(playerData, teams, rosterPlayers);
};

export { fromJSON as playerFromJson };
