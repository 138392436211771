import has from 'lodash/has';
import transform from 'lodash/transform';
import { Enums } from '@flightscope/baseball-stats';
import PitchPlayerColors from '@/enums/PitchColors';
import PitchCall from '../../../enums/PitchCall';
import SoftballPitchPlayerColors from '@/enums/SoftballPitchColors';

const colors = {
  red: {
    thunderbird: '#d11313',
    newYorkPink: '#d17d7d',
  },
  orange: {
    piazazz: '#fb8c00',
  },
  blue: {
    default: '#0000FF',
    picton: '#14adeb',
  },
  green: {
    vidaLoca: '#60ae20',
  },
  grey: {
    alto: '#dddddd',
    doveGray: '#6e6e6e',
  },
  black: {
    default: 'rgba(17,17,17,0.4)',
    almost: '#111111'
  },
  yelow: {
    dolly: '#fffb82',
  },
  purple: {
    lavender: '#967bb6'
  }
};

const SwingDecisionSeriesColorMapping = {
  [Enums.SwingDecision.Contact.key]: colors.green.vidaLoca,
  [Enums.SwingDecision.Whiff.key]: colors.red.thunderbird,
  [Enums.SwingDecision.Unclassified.key]: colors.grey.doveGray,
};

const GenericColorMapping = {
  // strike zone
  strikeZone: colors.black.default,
  strikeZoneSectors: colors.black.default,
  foulLine: colors.grey.alto,
  grassLine: colors.grey.alto,

  hiddenSeries: 'transparent',
  default: colors.blue.default,
};

// a little different then the one above
const CommonColorMapping = {
  // strike zone
  strikeZone: colors.grey.alto,
  strikeZoneSectors: colors.grey.alto,
  foulLine: colors.grey.alto,
  grassLine: colors.grey.alto,

  hiddenSeries: 'transparent',
  default: colors.blue.default,
};

const PitchCallSeriesColorMapping = {
  [PitchCall.BallInPlay.key]: colors.blue.picton,

  [PitchCall.Ball.key]: colors.green.vidaLoca,
  [PitchCall.FoulBall.key]: colors.red.thunderbird,
  [PitchCall.CalledStrike.key]: colors.red.thunderbird,
  [PitchCall.SwingingStrike.key]: colors.red.thunderbird,
  [PitchCall.IntentionalBall.key]: colors.green.vidaLoca,

  [PitchCall.HitByPitch.key]: colors.grey.doveGray,
  [PitchCall.Unidentified.key]: colors.grey.doveGray,

  [PitchCall.ContactOut.key]: colors.black.almost,

  ...CommonColorMapping,
};

const PitchResultSeriesColorMapping = {
  // strikes
  [Enums.PitchResult.SwingingStrike.key]: colors.red.thunderbird,
  [Enums.PitchResult.CalledStrike.key]: colors.red.thunderbird,
  [Enums.PitchResult.StrikeoutSwinging.key]: colors.red.thunderbird,
  [Enums.PitchResult.StrikeoutLooking.key]: colors.red.thunderbird,

  // hit or reached base by error
  [Enums.PitchResult.Single.key]: colors.black.almost,
  [Enums.PitchResult.Double.key]: colors.black.almost,
  [Enums.PitchResult.Triple.key]: colors.black.almost,
  [Enums.PitchResult.HomeRun.key]: colors.black.almost,
  [Enums.PitchResult.HitByPitch.key]: colors.grey.doveGray,
  [Enums.PitchResult.Error.key]: colors.black.almost,

  // ball
  [Enums.PitchResult.Ball.key]: colors.green.vidaLoca,
  [Enums.PitchResult.IntentionalBall.key]: colors.green.vidaLoca,
  [Enums.PitchResult.FoulBall.key]: colors.red.thunderbird,

  // other
  [Enums.PitchResult.ContactOut.key]: colors.grey.doveGray,
  [Enums.PitchResult.Unidentified.key]: colors.grey.doveGray,

  ...CommonColorMapping,
};

// Based on https://docs.google.com/document/d/1CuDdyyptwcyPUZzB5jEPjaoPG0jTv4L-iycaPM2ylFI/edit
const PitchTypeSeriesColorMapping = (process.env.VUE_APP_SPORT_TYPE === Enums.SportType.Baseball.key)
? {
  ...PitchPlayerColors,
  ...CommonColorMapping,
}
: {
  // softball override, see https://www.figma.com/design/wmbWfgrrs8XzqRUzSZkXV7/FS-Baseball---Settings?node-id=2423-6667&t=GS7IBufy8W368uQ1-0
  ...SoftballPitchPlayerColors,
  ...CommonColorMapping,
};

/**
 *
 * @param {string} key
 * @param {Record<string, string> & { default: string }} colorMapper
 */
const getColorForSet = (key, colorMapper) => {
  if (has(colorMapper, key)) {
    return colorMapper[key];
  }
  return colorMapper.default;
}

const PitchResultCombinedColorMapping = transform(Enums.PitchResultCombined, (acc, { pitchCall, key }) => {
  acc[key] = getColorForSet(pitchCall, PitchCallSeriesColorMapping);
  return acc;
}, {...CommonColorMapping })

const QualityOfContactColorMapping = {
  [Enums.QualityOfContact.Barrel.key]: colors.red.thunderbird,
  [Enums.QualityOfContact.SolidContact.key]: colors.red.newYorkPink,
  [Enums.QualityOfContact.FlareBurner.key]: colors.orange.piazazz,
  [Enums.QualityOfContact.PoorlyUnder.key]: colors.blue.picton,
  [Enums.QualityOfContact.PoorlyTopped.key]: colors.green.vidaLoca,
  [Enums.QualityOfContact.PoorlyWeak.key]: colors.yelow.dolly,
  [Enums.QualityOfContact.Unidentified.key]: colors.grey.doveGray,

  hiddenSeries: 'transparent',
  default: colors.blue.default,
};

const BattedBallColorMapping = {
  [Enums.BattedBallType.FlyBall.key]: colors.green.vidaLoca,
  [Enums.BattedBallType.LineDrive.key]: colors.red.thunderbird,
  [Enums.BattedBallType.GroundBall.key]: colors.yelow.dolly,
  [Enums.BattedBallType.PopUp.key]: colors.purple.lavender,
  [Enums.BattedBallType.Unidentified.key]: colors.grey.doveGray,
}

export {
  getColorForSet,
  CommonColorMapping,
  GenericColorMapping,
  PitchResultSeriesColorMapping,
  PitchTypeSeriesColorMapping,
  QualityOfContactColorMapping,
  SwingDecisionSeriesColorMapping,
  PitchCallSeriesColorMapping,
  PitchResultCombinedColorMapping,
  BattedBallColorMapping
};
