export default {
  inject: ['repo'],

  props: {
    PlayerRepo: {
      default() {
        return this.repo.get('players');
      },
    },
  },
};
