import mapValues from 'lodash/mapValues';
import map from 'lodash/map';
import Messages from '@/enums/Messages';
import { dropdownIcon } from '@/utils/helpers';

let checkboxGroups = {
  balls: {
    title: 'Balls',
    all: true,
    items: [
      { text: '0', value: 0 },
      { text: '1', value: 1 },
      { text: '2', value: 2 },
      { text: '3', value: 3 },
    ],
  },
  strikes: {
    title: 'Strikes',
    all: true,
    items: [
      { text: '0', value: 0 },
      { text: '1', value: 1 },
      { text: '2', value: 2 },
    ],
  },
};

export default {
  name: 'ScoringCountsFilter',

  components: {
    CheckboxItemGroup: () => import('@/components/ui/selection-controls/CheckboxItemGroup.vue'),
  },

  props: {
    balls: {
      type: [Array, Number, String],
    },
    strikes: {
      type: [Array, Number, String],
    },

    label: {
      type: String,
      default: '',
    },

    allText: {
      type: String,
      default: Messages.ALL,
    },

    minWidth: {
      type: Number,
      default: 219,
    },

    maxWidth: {
      type: Number,
      default: 490,
    },

    multiple: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      picker: false,
      closeOnClick: true,
      closeOnContentClick: false,
      messages: Messages,
    };
  },

  computed: {
    groups() {
      return checkboxGroups;
    },

    allValues() {
      return mapValues(this.groups, (o) => map(o.items, (i) => i.value));
    },

    textFieldValue() {
      const balls = this.selectedBalls.length ? this.selectedBalls : this.allText;
      const strikes = this.selectedStrikes.length ? this.selectedStrikes : this.allText;

      return `Balls: ${balls}  - Strikes: ${strikes}`;
    },

    selectedBalls: {
      get() {
        if (this.balls) {
          return this.balls;
        }
        return this.multiple ? [] : '';
      },
        set(value) {
          this.$emit('update:balls', value);
        },
    },

    selectedStrikes: {
      get() {
        if (this.strikes) {
          return this.strikes;
        }
        return this.multiple ? [] : '';
      },
      set(value) {
        this.$emit('update:strikes', value);
      },
    },

    appendIcon() {
      return dropdownIcon(this.picker);
    }
  },

  methods: {
    setAllValues() {
      this.selectedBalls = this.allValues.balls;
      this.selectedStrikes = this.allValues.strikes;
    },
  },
};
