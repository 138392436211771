import { Enums } from '@flightscope/baseball-stats';

/**
 * @see https://www.figma.com/design/QerEhObSQNWu5vt1Lf8Zyc/FS-Softball---Settings?node-id=1585-6209&t=zn7vS6yJIHynU9gM-0
 */
const SoftballPitchPlayerColors = {
  // fastballs
  // [Enums.BaseballPitchType.FourSeamFastball.key]: '#BE2E00',
  // [Enums.BaseballPitchType.TwoSeamFastball.key]: '#A78B71',
  // [Enums.BaseballPitchType.Sinker.key]: '#EC9704',
  // [Enums.BaseballPitchType.Cutter.key]: '#F7C815',
  [Enums.SoftballPitchType.Fastball.key]: '#840000',
  // softball fastballs
  [Enums.SoftballPitchType.RiseBall.key]: '#DD0000',
  [Enums.SoftballPitchType.DropBall.key]: '#A15200',

  // breaking
  [Enums.SoftballPitchType.Slider.key]: '#58A0E7',
  [Enums.SoftballPitchType.Curveball.key]: '#00569D',
  // [Enums.BaseballPitchType.KnuckleCurve.key]: '#00B247',
  // [Enums.BaseballPitchType.Slurve.key]: '#D5DA4F',
  [Enums.SoftballPitchType.Screwball.key]: '#008080',
  // [Enums.BaseballPitchType.BreakingBall.key]: '#5EB5AB',

  // offspeed
  [Enums.SoftballPitchType.Changeup.key]: '#670089',
  // [Enums.BaseballPitchType.CircleChangeup.key]: '#4E5FF8',
  // [Enums.BaseballPitchType.Splitter.key]: '#00569D',
  // [Enums.BaseballPitchType.Forkball.key]: '#D58DC8',
  // [Enums.BaseballPitchType.Palmball.key]: '#BC44A7',
  // [Enums.BaseballPitchType.Knuckleball.key]: '#E046F5',
  // [Enums.BaseballPitchType.Eephus.key]: '#670089',
  // [Enums.BaseballPitchType.Gyroball.key]: '#81AEDA',
  // [Enums.BaseballPitchType.Offspeed.key]: '#499FF1',

  // other
  [Enums.SoftballPitchType.Other.key]: '#575757',
  [Enums.SoftballPitchType.Unidentified.key]: '#575757',


  // strike zone
  strikeZone: '#dddddd',
  strikeZoneSectors: '#dddddd',
  foulline: '#dddddd',
  grassline: '#dddddd',

  hiddenSeries: 'transparent',
};

export default SoftballPitchPlayerColors;
