import { mapGetters } from 'vuex';
import { Enums, Providers } from '@flightscope/baseball-stats';
import { mapBattingSummaryStats } from '@/utils/mappers';
import { unitSymbolProvider } from '@/filters/units';
import { unitsConverter } from '@/plugins/converter';
import { ReportsEnum } from '../configs/ReportsEnum';
import ExportCsvBtn from '@/modules/session/views/ExportCsvBtn.vue';
import { EReportContext } from '../../../enums/reports';
import { PitchTypeSeriesColorMapping as PitchColors } from '@/components/ui/charts/ColorMapping';

const NoData = () => import('@/components/core/NoData.vue');

export default {
  name: 'ProfileBattingSummary',

  props: {
    reportLoading: {
      type: Boolean,
    },
    player: {
      type: Object,
    },
    report: {
      type: Array,
      default: () => [],
    },
    dynamicFilters: {
      type: Object,
      default: () => {},
    },
    expandable: {
      type: Boolean,
      default: true,
    },
    showDummyData: {
      type: Boolean,
      default: false,
    },
    showExport: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ExportCsvBtn,
    ThExt: () => import('@/components/ui/tables/ThExt.vue'),
    ProfileSummaryExpandable: () =>
      import(
        /* webpackChunkName: "playerProfiles" */
        '@/modules/player-profiles/reports/ProfileSummaryExpandable.vue'
      ),
    NoData,
  },

  data() {
    return {
      chartSeries: null,
      mixedChartSeries: null,
      expanded: [],
      enabledCharts: [
        { key: 'batting-batters-performance', config: ReportsEnum['batting-batters-performance'] },
        { key: 'batting-plate-discipline', config: ReportsEnum['batting-plate-discipline'] },
      ],
      PitchColors,
      tableOptions: {
        sortBy: [Enums.BattingStats.NumberOfPitches.key],
        sortDesc: [true],
      },
      battingStatsEnum: Enums.BattingStats,
      isFocused: false,
    };
  },

  computed: {
    ...mapGetters(['selectedUnitsSystem']),
    ctx() {
      return EReportContext.BATTING;
    },
    summaryDataArray() {
      if (this.report && this.report.length) {
        const provider = new Providers.CumulativeBattingSummaryDataProvider();
        return provider.getDataForHittingSummary(this.report, this.dynamicFilters);
      }
      return [];
    },
    summaryData() {
      if (this.summaryDataArray.length) {
        return this.summaryDataArray[0];
      }
      return null;
    },
    mappedData() {
      let { summaryData } = this;
      if (summaryData) {
        let { pitchTypes } = summaryData;
        // TODO: remove converting data in mapper. It should be connverted later.
        return mapBattingSummaryStats(pitchTypes, this.selectedUnitsSystem.system);
      }
      return [];
    },
    headers() {
      return [
        { text: 'Pitch Type', value: 'PitchTypeLabel' },
        this.getHeaderItem(Enums.BattingStats.NumberOfPitches, false, { align: 'center', sortable: true }),
        // this.getHeaderItem(Enums.BattingStats.HardHitRate, false, { align: 'center' }), // BBP-1161 - hide for now
        this.getHeaderItem({ ...Enums.BattingStats.ExitSpeedAvg, short: 'AVG Exit Velo' }, true, { align: 'center' }),
        this.getHeaderItem(Enums.BattingStats.ExitSpeedMax, true, { align: 'center' }),
        this.getHeaderItem(Enums.BattingStats.HitLaunchVAvg, true, { align: 'center' }),
        this.getHeaderItem(Enums.BattingStats.CarryMax, true, { align: 'center' }),
        this.getHeaderItem(Enums.BattingStats.KZoneRate, false, { align: 'center' }),
        this.getHeaderItem(Enums.BattingStats.Hits, false, { align: 'center' }),
        this.getHeaderItem(Enums.BattingStats.TotalBases, false, { align: 'center' }),
        this.getHeaderItem(Enums.BattingStats.Strikeouts, false, { align: 'center' }),
        this.getHeaderItem(Enums.BattingStats.StrikeoutRate, false, { align: 'center' }),
        this.getHeaderItem(Enums.BattingStats.Swings, false, { align: 'center' }),
        this.getHeaderItem(Enums.BattingStats.Whiffs, false, { align: 'center', text: 'Misses' }),
        this.getHeaderItem(Enums.BattingStats.SwingRate, false, { align: 'center' }),
        this.getHeaderItem(Enums.BattingStats.WhiffRate, false, { align: 'center' }),
        this.getHeaderItem(Enums.BattingStats.ZSwingRate, false, { align: 'center' }),
        this.getHeaderItem(Enums.BattingStats.ZContactRate, false, { align: 'center' }),
        this.getHeaderItem(Enums.BattingStats.OSwingRate, false, { align: 'center' }),
        this.getHeaderItem(Enums.BattingStats.OContactRate, false, { align: 'center' }),
        { value: 'data-table-expand' },
      ];
    },
    captionSummary() {
      if (!this.summaryData) {
        return [];
      }

      return [
        {
          text: Enums.BattingStats.HomeRuns.name,
          value: this.getCaptionValue(Enums.BattingStats.HomeRuns),
          desc: Enums.BattingStats[Enums.BattingStats.HomeRuns.key].description,
        },
        { divider: true },
        {
          text: Enums.BattingStats.TotalBases.name,
          value: this.getCaptionValue(Enums.BattingStats.TotalBases),
          desc: Enums.BattingStats[Enums.BattingStats.TotalBases.key].description,
        },
        { divider: true },
        {
          text: Enums.BattingStats.BattedBalls.name,
          value: this.getCaptionValue(Enums.BattingStats.BattedBalls),
          desc: Enums.BattingStats[Enums.BattingStats.BattedBalls.key].description,
        },
        { divider: true },
        {
          text: Enums.BattingStats.GroundBallRate.name,
          value: this.getCaptionValue(Enums.BattingStats.GroundBallRate),
          desc: Enums.BattingStats[Enums.BattingStats.GroundBallRate.key].description,
        },
        { divider: true },
        {
          text: Enums.BattingStats.LineDriveRate.name,
          value: this.getCaptionValue(Enums.BattingStats.LineDriveRate),
          desc: Enums.BattingStats[Enums.BattingStats.LineDriveRate.key].description,
        },
        { divider: true },
        {
          text: Enums.BattingStats.FlyBallRate.name,
          value: this.getCaptionValue(Enums.BattingStats.FlyBallRate),
          desc: Enums.BattingStats[Enums.BattingStats.FlyBallRate.key].description,
        },
        { divider: true },
        {
          text: Enums.BattingStats.PopUpRate.name,
          value: this.getCaptionValue(Enums.BattingStats.PopUpRate),
          desc: Enums.BattingStats[Enums.BattingStats.PopUpRate.key].description,
        },
        { divider: true },
        {
          text: Enums.BattingStats.PitchesPerPa.name,
          value: this.getCaptionValue(Enums.BattingStats.PitchesPerPa),
          desc: Enums.BattingStats[Enums.BattingStats.PitchesPerPa.key].description,
        },
        { divider: true },
        {
          text: Enums.BattingStats.PlateAppearances.short,
          value: this.getCaptionValue(Enums.BattingStats.PlateAppearances),
          desc: Enums.BattingStats[Enums.BattingStats.PlateAppearances.key].description,
        },
      ];
    },

    showExportBtn() {
      return this.$can('export', 'Csv') && this.showExport && this.player;
    },

    dataTableId() {
      return `profile-batting-summary-${this.player?.Id}`;
    },

    scrollWrapperSelector() {
      return `#${this.dataTableId} .v-data-table__wrapper`;
    },
  },

  methods: {
    getCaptionValue(Enum) {
      return unitsConverter.convertType(this.summaryData.summary[Enum.key], Enum.type).formatWithSymbol('-', 2);
    },

    getHeaderItem(_enum, displayUnit = false, options = {}) {
      const unit = displayUnit ? ` [${unitSymbolProvider(_enum.type, this.selectedUnitsSystem.system)}]` : '';
      return Object.assign(
        {
          text: `${_enum.short}${unit}`,
          value: _enum.key,
          title: _enum.description,
        },
        options,
      );
    },

    getSummaryItem(key) {
      if (this.summaryData) {
        return this.summaryData.summary[key];
      }

      return undefined;
    },
    onTableScroll(e) {
      if (this.isFocused) {
        this.$emit('horizontalScroll', e);
      }
    },
  },
};
