const EReportContext = Object.freeze({
  BATTING: {
    key: 'batting',
    label: 'Batting',
  },
  PITCHING: {
    key: 'pitching',
    label: 'Pitching',
  }
});

const EReportType = Object.freeze({
  SUMMARY: 'summary',
  VIDEO: 'videos',
});

export { EReportContext, EReportType };
