const TextWithTooltip = () => import('../../../components/ui/tooltips/TextWithTooltip.vue');

export const levels = {
  LEVEL0: 'LEVEL-0',
  LEVEL1: 'LEVEL-1',
  LEVEL2: 'LEVEL-2',
};

export const levelIDs = {
  LEVEL0: 'LEVEL-0',
  LEVEL1M: 'LEVEL-1-M',
  LEVEL1Y: 'LEVEL-1-Y',
  LEVEL2M: 'LEVEL-2-M',
  LEVEL2Y: 'LEVEL-2-Y',
};

export const levelToRole = {
  CUSTOM: 'ws_bb_level_custom',
  [levels.LEVEL0]: 'ws_bb_level0',
  [levels.LEVEL1]: 'ws_bb_level1',
  [levels.LEVEL2]: 'ws_bb_level2',
};

export const roleToLevel = {
  [levelToRole[levels.LEVEL0]]: levels.LEVEL0,
  [levelToRole[levels.LEVEL1]]: levels.LEVEL1,
  [levelToRole[levels.LEVEL2]]: levels.LEVEL2,
};

export const levelIDToType = {
  [levelIDs.LEVEL0]: levels.LEVEL0,
  [levelIDs.LEVEL1M]: levels.LEVEL1,
  [levelIDs.LEVEL1Y]: levels.LEVEL1,
  [levelIDs.LEVEL2M]: levels.LEVEL2,
  [levelIDs.LEVEL2Y]: levels.LEVEL2,
};

export const PLAN_TRANSITION_ACTION = Object.freeze({
  BUY: 'buy',
  CANCEL: 'cancel',
  DOWNGRADE: 'downgrade',
  UPDATE: 'update',
  UPGRADE: 'upgrade',
});

// eslint-disable-next-line import/prefer-default-export
export const servicePlans = {
  [levels.LEVEL0]: {
    title: 'Basic',
    levelText: 'Basic',
    description: '',
    featuresHeading: "With this plan you'll get data:",
    features: [{ text: 'Player Profile' }, { text: 'Top Exit Speed' }, { text: 'Top FB Velo' }],
    featuresText: 'Player Profile, Top Fastball Velocity, Top Exit Velocity',
    backgroundImagePath: `/img/${process.env.VUE_APP_SPORT_TYPE}/plan-type-free.jpg`,
  },
  [levels.LEVEL1]: {
    title: 'Analytics',
    levelText: 'Analytics',
    description: '',
    discount: '20',
    featuresHeading: 'Includes all features from <strong>Basic</strong><br><br><strong class="text-uppercase">plus</strong>',
    features: [
      { text: 'Pitching and batting statistics' },
      { text: 'In-detail pitching and batting analysis' },
      { text: 'Historical performance statistics' },
    ],
    featuresText: 'Features from <strong>Basic</strong> + Batting Summary and Spray Charts, Detailed Pitching and Batting Reports, Pitching Summary, Pitch Location Heat Map, Historical Performance Data',
    backgroundImagePath: `/img/${process.env.VUE_APP_SPORT_TYPE}/plan-type-analytics.jpg`,
  },
  [levels.LEVEL2]: {
    title: 'All Access Scout',
    levelText: 'All Access Scout',
    description: '',
    discount: '60',
    featuresHeading: 'Includes all features from <strong>Basic</strong> & <strong>Analytics</strong><br><br><strong class="text-uppercase">plus</strong>',
    features: [
      { text: 'Batting and pitching videos' },
      { text: 'Uploading media to Twitter' },
      {
        component: TextWithTooltip,
        textSuffix: ' included*',
        props: {
          activator: '<span class="text-decoration-underline white--text cursor-pointer">FS Video Clipper</span>',
          title: `FS Video Clipper is a tool that automates the clipping of <strong>Action Videos</strong>,
            where video footage from any digital video recorder may be uploaded and based on the trigger time stamp
            from <strong>FlightScope data</strong>, or <strong>FlightScope StatGrab App</strong>, the video footage is
            automatically clipped and the action clips uploaded to <strong>FS Cloud</strong>, where the videos are
            associated with the pitch/hit for every play.`,
          maxWidth: 390,
          color: 'secondary',
        },
      },
      { text: 'Video limit: 1000 videos*' },
    ],
    featuresText: 'Features from <strong>Analytics</strong> + Tracking Players, Hitting and Pitching videos, Batting Performance Tracking, Strike Zone Heat Map, Release Point Heat Map, Extension Heat Map, Pitch Movement Heat Map, Pitcher Performance Tracking, Sharing video clips to Twitter',
    backgroundImagePath: `/img/${process.env.VUE_APP_SPORT_TYPE}/plan-type-video-data.jpg`,
    additionalText: '*Coming soon with our new FS StatGrab application.',
  },
};

export const servicePlanFeatures = Object.freeze({
  viewPlayerProfile: { text: 'View Player Profile', key: 'viewPlayerProfile ' },
  topFastballVelocity: { text: 'Top Fastball Velocity', key: 'topFastballVelocity ' },
  topExitVelocity: { text: 'Top Exit Velocity', key: 'topExitVelocity ' },
  reviewScoringDataFromFsStatGrab: { text: 'Review scoring data from FS StatGrab', key: 'reviewScoringDataFromFsStatGrab' },
  battingSummaryAndSprayCharts: { text: 'Batting Summary and Spray Charts', key: 'battingSummaryAndSprayCharts' },
  detailedPitchingAndBattingReports: { text: 'Detailed Pitching and Batting Reports', key: 'detailedPitchingAndBattingReports' },
  historicalPerformanceData: { text: 'Historical Performance Data', key: 'historicalPerformanceData' },
  pitchingSummary: { text: 'Pitching Summary', key: 'pitchingSummary' },
  pitchLocationHeatMap: { text: 'Pitch Location Heat Map', key: 'pitchLocationHeatMap' },
  trackAnyPlayer: { text: 'Track any Player', key: 'trackAnyPlayer' },
  hittingAndPitchingVideos: { text: 'Hitting and Pitching videos', key: 'hittingAndPitchingVideos' },
  battingPerformanceTracking: { text: 'Batting Performance Tracking', key: 'battingPerformanceTracking' },
  strikeZoneHeatMap: { text: 'Strike Zone Heat Map', key: 'strikeZoneHeatMap' },
  releasePointHeatMap: { text: 'Release Point Heat Map', key: 'releasePointHeatMap' },
  extensionHeatMap: { text: 'Extension Heat Map', key: 'extensionHeatMap' },
  pitchMovementHeatMap: { text: 'Pitch Movement Heat Map', key: 'pitchMovementHeatMap' },
  pitcherPerformanceTracking: { text: 'Pitcher Performance Tracking', key: 'pitcherPerformanceTracking' },
  reviewUpTo1000videoClips: { text: 'Review up to 1000 video clips from FS StatGrab', key: 'reviewUpTo1000videoClips' },
  sharePitchingAndHittingVideoClipsToTwitter: { text: 'Share pitching and hitting video clips to Twitter', key: 'sharePitchingAndHittingVideoClipsToTwitter' },

});

// eslint-disable-next-line import/prefer-default-export
export const servicePlansTable = {
  [levels.LEVEL0]: {
    title: 'Basic',
    levelText: 'Basic',
    features: [
      servicePlanFeatures.viewPlayerProfile.key,
      servicePlanFeatures.topFastballVelocity.key,
      servicePlanFeatures.topExitVelocity.key,
    ],
  },
  [levels.LEVEL1]: {
    title: 'Analytics',
    levelText: 'Analytics',
    discount: '20',
    features: [
      servicePlanFeatures.viewPlayerProfile.key,
      servicePlanFeatures.topFastballVelocity.key,
      servicePlanFeatures.topExitVelocity.key,
      servicePlanFeatures.reviewScoringDataFromFsStatGrab.key,
      servicePlanFeatures.battingSummaryAndSprayCharts.key,
      servicePlanFeatures.detailedPitchingAndBattingReports.key,
      servicePlanFeatures.historicalPerformanceData.key,
      servicePlanFeatures.pitchingSummary.key,
      servicePlanFeatures.pitchLocationHeatMap.key,
    ],
  },
  [levels.LEVEL2]: {
    title: 'All Access Scout',
    levelText: 'All Access Scout',
    discount: '60',
    features: [
      servicePlanFeatures.viewPlayerProfile.key,
      servicePlanFeatures.topFastballVelocity.key,
      servicePlanFeatures.topExitVelocity.key,
      servicePlanFeatures.reviewScoringDataFromFsStatGrab.key,
      servicePlanFeatures.battingSummaryAndSprayCharts.key,
      servicePlanFeatures.detailedPitchingAndBattingReports.key,
      servicePlanFeatures.historicalPerformanceData.key,
      servicePlanFeatures.pitchingSummary.key,
      servicePlanFeatures.pitchLocationHeatMap.key,
      servicePlanFeatures.trackAnyPlayer.key,
      servicePlanFeatures.hittingAndPitchingVideos.key,
      servicePlanFeatures.battingPerformanceTracking.key,
      servicePlanFeatures.strikeZoneHeatMap.key,
      servicePlanFeatures.releasePointHeatMap.key,
      servicePlanFeatures.extensionHeatMap.key,
      servicePlanFeatures.pitchMovementHeatMap.key,
      servicePlanFeatures.pitcherPerformanceTracking.key,
      servicePlanFeatures.reviewUpTo1000videoClips.key,
      servicePlanFeatures.sharePitchingAndHittingVideoClipsToTwitter.key,
    ],
  },
};

export const servicePlanLevelsMapping = {
  [levelIDs.LEVEL0]: servicePlans[levels.LEVEL0],
  [levelIDs.LEVEL1M]: servicePlans[levels.LEVEL1],
  [levelIDs.LEVEL1Y]: servicePlans[levels.LEVEL1],
  [levelIDs.LEVEL2M]: servicePlans[levels.LEVEL2],
  [levelIDs.LEVEL2Y]: servicePlans[levels.LEVEL2],
};

// TODO: Note, it could be based on the "type" property from service plan list items (from API)
export const servicePlanLevelsTableMapping = {
  [levelIDs.LEVEL0]: servicePlansTable[levels.LEVEL0],
  [levelIDs.LEVEL1M]: servicePlansTable[levels.LEVEL1],
  [levelIDs.LEVEL1Y]: servicePlansTable[levels.LEVEL1],
  [levelIDs.LEVEL2M]: servicePlansTable[levels.LEVEL2],
  [levelIDs.LEVEL2Y]: servicePlansTable[levels.LEVEL2],
};

export const submodules = Object.freeze({
  servicePlan: 'servicePlan',
});

export const submoduleActions = Object.freeze({
  plansRequest: 'plansRequest',
});
