import omit from 'lodash/omit';
import values from 'lodash/values';
import difference from 'lodash/difference';
import intersectionBy from 'lodash/intersectionBy';
import { GenericPitchType, GenericPitchTypeGroup } from '@/enums/Generic';
import Selectable from '@/components/mixins/Selectable';
import FilterableSelector from '@/components/mixins/FilterableSelector';
import CheckboxGroupDropdown from '@/components/ui/dropdowns/CheckboxGroupDropdown.vue';

// TODO: keep as example, remove later
// const checkboxGroups = [
//   {
//     title: 'Breaking',
//     color: 'cyan',
//     all: true,
//     items: [
//       { text: 'Slider', value: 'SL' },
//       { text: 'Curveball', value: 'CU' },
//       { text: 'Knuckleball', value: 'KN' },
//     ],
//   },
//   {
//     title: 'Offspeed',
//     color: 'green',
//     all: true,
//     items: [
//       { text: 'Splitter', value: 'SP' },
//       { text: 'Changeup', value: 'CH' },
//     ],
//   },
//   {
//     title: 'Fastball',
//     color: 'red',
//     all: true,
//     items: [
//       { text: 'Cutter', value: 'CT' },
//       { text: 'Sinker', value: 'SI' },
//     ],
//   },
//   {
//     title: 'Ungrouped',
//     all: true,
//     items: [
//       { text: 'Dirtball', value: 'DB' },
//       { text: 'Drop Ball', value: 'DR' },
//     ],
//   },
// ];

export default {
  name: 'PitchTypeDropdown',

  mixins: [Selectable, FilterableSelector],

  props: {
    label: {
      type: String,
      required: true,
    },
  },
  components: {
    CheckboxGroupDropdown,
  },
  methods: {
    mapColor(key) {
      switch (key) {
        case GenericPitchTypeGroup.breaking.key:
          return 'breaking';
        case GenericPitchTypeGroup.fastballs.key:
          return 'fastballs';
        case GenericPitchTypeGroup.changeup.key:
          return 'changeup';
        case GenericPitchTypeGroup.other.key:
          return 'other';
        default:
          return '';
      }
    },

    mapGroups(o) {
      return {
        title: o.name === 'Other' ? 'Ungrouped' : o.name,
        color: this.mapColor(o.key),
        all: true,
        items: intersectionBy(this.typesFromEnum, o.results.map(this.mapTypes), 'value'),
      };
    },

    mapTypes(key) {
      return { value: key };
    },
  },

  computed: {
    groupsFromEnum() {
      return values(omit(GenericPitchTypeGroup, 'all'));
    },

    typesFromEnum() {
      return Object.values(GenericPitchType).map(({ key, name }) => ({ text: name, value: key }));
    },

    groups() {
      return this.groupsFromEnum.map(this.mapGroups);
    },

    allOptions() {
      return this.typesFromEnum.map(i => i.value);
    },

    filteredOptions() {
      if (this.itemsPolicy === 'blacklist') {
        return difference(this.allOptions, this.list);
      } else if (this.itemsPolicy === 'whitelist') {
        return this.list;
      }
      return this.allOptions;
    },
  },
};
