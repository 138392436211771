const primaryMarker = {
  color: 'rgb(49,130,189)',
  opacity: 0.7,
};

const secondaryMarker = {
  color: 'rgb(204,204,204)',
  opacity: 0.5,
};

const Markers = {
  primaryMarker,
  secondaryMarker,
};

export { Markers };
