<script>
export default {
  name: 'ThExt',
  props: {
    title: {
      type: String,
      default: '',
    },
    dfn: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String,
      default: '',
    },
  },
  computed: {
    textAlign() {
      return this.align ? `text-${this.align}` : '';
    },
  },
};
</script>

<template>
<th :class="textAlign">
  <template>
    <dfn v-if="dfn" :title="title"><slot></slot></dfn>
    <v-tooltip v-else-if="tooltip" top>
      <template v-slot:activator="{ on }">
        <span v-on="on"><slot></slot></span>
      </template>
      <span v-html="title"></span>
    </v-tooltip>
    <slot v-else></slot>
  </template>
</th>
</template>
