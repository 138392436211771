import { Enums } from '@flightscope/baseball-stats';

/**
 * @see https://www.figma.com/design/wmbWfgrrs8XzqRUzSZkXV7/FS-Baseball---Settings?node-id=2423-6667&t=GS7IBufy8W368uQ1-0
 */
const PitchPlayerColors = {
  // fastballs
  [Enums.BaseballPitchType.FourSeamFastball.key]: '#BE2E00',
  [Enums.BaseballPitchType.TwoSeamFastball.key]: '#A78B71',
  [Enums.BaseballPitchType.Sinker.key]: '#EC9704',
  [Enums.BaseballPitchType.Cutter.key]: '#F7C815',
  [Enums.BaseballPitchType.Fastball.key]: '#C07042',

  // breaking
  [Enums.BaseballPitchType.Slider.key]: '#41670A',
  [Enums.BaseballPitchType.Curveball.key]: '#96A300',
  [Enums.BaseballPitchType.KnuckleCurve.key]: '#00B247',
  [Enums.BaseballPitchType.Slurve.key]: '#D5DA4F',
  [Enums.BaseballPitchType.Screwball.key]: '#99D259',
  [Enums.BaseballPitchType.BreakingBall.key]: '#5EB5AB',

  // offspeed
  [Enums.BaseballPitchType.Changeup.key]: '#9239BA',
  [Enums.BaseballPitchType.CircleChangeup.key]: '#4E5FF8',
  [Enums.BaseballPitchType.Splitter.key]: '#00569D',
  [Enums.BaseballPitchType.Forkball.key]: '#D58DC8',
  [Enums.BaseballPitchType.Palmball.key]: '#BC44A7',
  [Enums.BaseballPitchType.Knuckleball.key]: '#E046F5',
  [Enums.BaseballPitchType.Eephus.key]: '#670089',
  [Enums.BaseballPitchType.Gyroball.key]: '#81AEDA',
  [Enums.BaseballPitchType.Offspeed.key]: '#499FF1',

  // other
  [Enums.BaseballPitchType.Other.key]: '#575757',
  [Enums.BaseballPitchType.Unidentified.key]: '#575757',
  [Enums.BaseballPitchType.Pitchout.key]: '#575757',


  // strike zone
  strikeZone: '#dddddd',
  strikeZoneSectors: '#dddddd',
  foulline: '#dddddd',
  grassline: '#dddddd',

  hiddenSeries: 'transparent',
};

export default PitchPlayerColors;
