import get from 'lodash/get';
import pick from 'lodash/pick';
import transform from 'lodash/transform';
import { Enums, Providers } from '@flightscope/baseball-stats';
import { GenericPitchType } from '@/enums/Generic';
import { EReportContext } from '../enums/reports';
import { PlayerModel } from '../models/PlayerModel';
import { convertUnitNoSymbol, unitSymbolProvider } from '../filters/units';

/**
 * @var {ColEnums} PlayerStaticCols
 */
export const PlayerStaticCols = {
    TeamName: {
      path: 'team.name',
      key: 'TeamName',
      name: 'Team Name',
      short: 'Team Name',
    },
    TeamId: {
      path: 'team.id',
      key: 'TeamId',
      name: 'TeamId',
      short: 'TeamId',
    },
    PlayerName: {
      path: 'FormattedName',
      key: 'PlayerName',
      name: 'PlayerName',
      short: 'PlayerName',
    },
    PlayerId: {
      path: 'id',
      key: 'PlayerId',
      name: 'PlayerId',
      short: 'PlayerId',
    },
    Number: {
      path: 'RosterPlayers[0].number',
      key: 'Number',
      name: 'Number',
      short: 'Number',
    },
    Position: {
      path: 'RosterPlayers[0].position[0]',
      key: 'Position',
      name: 'Position',
      short: 'Position',
    },
    Throwing: {
      path: 'Throws',
      key: 'Throwing',
      name: 'Throwing',
      short: 'Throwing',
    },
    Batting: {
      path: 'Bats',
      key: 'Batting',
      name: 'Batting',
      short: 'Batting',
    },
};

/**
 *
 * @param {PlayerModel} player
 * @param {string?} ctx
 */
export function getPlayerPartial(player, ctx) {
  const out = {};
  const list = [
    PlayerStaticCols.TeamName,
    PlayerStaticCols.TeamId,
    PlayerStaticCols.PlayerName,
    PlayerStaticCols.PlayerId,
    PlayerStaticCols.Number,
    PlayerStaticCols.Position,
  ];
  for (let i = 0; i < list.length; i++) {
    const { key, path } = list[i];
    out[key] = get(player, path, '');
  }
  switch(ctx) {
    case EReportContext.BATTING.key: {
      const { key, path } = PlayerStaticCols.Batting;
      out[key] = get(player, path, '');
      break
    }
    case EReportContext.PITCHING.key: {
      const { key, path } = PlayerStaticCols.Throwing;
      out[key] = get(player, path, '');
      break;
    }
  }
  return out;
}


const BattingRowPartialKeys = [
  // PitchType
  'pitchType',
  // NP
  Enums.BattingStats.NumberOfPitches.key,
  // "Exit Velo, avg [mph]"
  Enums.BattingStats.ExitSpeedAvg.key,
  // Max Exit Velo [mph]
  Enums.BattingStats.ExitSpeedMax.key,
  // "LA, avg [°]"
  Enums.BattingStats.HitLaunchVAvg.key,
  // Max Carry [ft]
  Enums.BattingStats.CarryMax.key,
  // Zone %
  Enums.BattingStats.KZoneRate.key,
  // H
  Enums.BattingStats.Hits.key,
  // TB
  Enums.BattingStats.TotalBases.key,
  // SO
  Enums.BattingStats.Strikeouts.key,
  // %K
  Enums.BattingStats.StrikeoutRate.key,
  // Swings
  Enums.BattingStats.Swings.key,
  // Misses
  Enums.BattingStats.Whiffs.key,
  // Swing%
  Enums.BattingStats.SwingRate.key,
  // Whiff%
  Enums.BattingStats.WhiffRate.key,
  // Z-Swing%
  Enums.BattingStats.ZSwingRate.key,
  // Z-Contact%
  Enums.BattingStats.ZContactRate.key,
  // O-Swing%
  Enums.BattingStats.OSwingRate.key,
  // O-Contact%
  Enums.BattingStats.OContactRate.key,
  // HR
  Enums.BattingStats.HomeRuns.key,
  // TB
  Enums.BattingStats.TotalBases.key,
  // BBE
  Enums.BattingStats.BattedBalls.key,
  // GB%
  Enums.BattingStats.GroundBallRate.key,
  // LD%
  Enums.BattingStats.LineDriveRate.key,
  // FB%
  Enums.BattingStats.FlyBallRate.key,
  // PO%
  Enums.BattingStats.PopUpRate.key,
  // P/PA
  Enums.BattingStats.PitchesPerPa.key,
  // PA
  Enums.BattingStats.PlateAppearances.key,
];

const PitchingRowPartialKeys = [
  'pitchType',
  Enums.PitchingStats.NumberOfPitches.key,
  Enums.PitchingStats.PitchUsage.key,
  Enums.PitchingStats.PitchSpeedAvg.key,
  Enums.PitchingStats.PitchSpeedMax.key,
  Enums.PitchingStats.PitchSpinAvg.key,
  Enums.PitchingStats.PitchMovementHAvg.key,
  Enums.PitchingStats.PitchMovementVAvg.key,
  Enums.PitchingStats.ExtensionAvg.key,
  Enums.PitchingStats.KZoneRate.key,
  Enums.PitchingStats.HitExitSpeedAvg.key,
  Enums.PitchingStats.HitsAllowed.key,
  Enums.PitchingStats.Strikeouts.key,
  Enums.PitchingStats.StrikeoutRate.key,
  Enums.PitchingStats.Swings.key,
  Enums.PitchingStats.Whiffs.key,
  Enums.PitchingStats.SwingRate.key,
  Enums.PitchingStats.WhiffRate.key,
  Enums.PitchingStats.SwingingStrikeRate.key,
  Enums.PitchingStats.ZSwingRate.key,
  Enums.PitchingStats.ZContactRate.key,
  Enums.PitchingStats.OSwingRate.key,
  Enums.PitchingStats.OContactRate.key,
];

const pitchTypeMapper = (v) => {
  if (v === 'ALL') {
    return 'All Pitches';
  }
  return Providers.EnumValueProvider.getValue(v, GenericPitchType, 'U', false, 'name');
};
const justReturnMapper = (v) => v;

const convertWrapper = ( _enum, system, options) => (v) => {
  return convertUnitNoSymbol(v, _enum.type, system, Object.assign({
    noValueFallback: '-',
  }, options));
}

const unitSuffix = (type, system) => {
  return `[${unitSymbolProvider(type, system)}]`;
};

const enumKeyMapAndConvert = (_enum, system, showUnit, options = {}) => {
  const newKey = showUnit ? `${_enum.short} ${unitSuffix(_enum.type, system)}` : _enum.short;
  return {
    [_enum.key]: [convertWrapper(_enum, system, options), newKey],
  };
};

const battingMappers = (system) => {
  return {
    pitchType: [pitchTypeMapper, 'PitchType'],
    [Enums.BattingStats.NumberOfPitches.key]: [justReturnMapper, Enums.BattingStats.NumberOfPitches.short],
    ...enumKeyMapAndConvert({
      ...Enums.BattingStats.ExitSpeedAvg,
      short: 'AVG Exit Velo'
     }, system, true, { fractionalDigits: 1 }),
     ...enumKeyMapAndConvert(Enums.BattingStats.ExitSpeedMax, system, true, { fractionalDigits: 1}),
     ...enumKeyMapAndConvert({
       ...Enums.BattingStats.HitLaunchVAvg,
       short: 'AVG LA'
      }, system, true, { fractionalDigits: 1 }),
      ...enumKeyMapAndConvert(Enums.BattingStats.CarryMax, system, true, { fractionalDigits: 1}),
      ...enumKeyMapAndConvert(Enums.BattingStats.KZoneRate, system, false, { fractionalDigits: 1}),
      ...enumKeyMapAndConvert(Enums.BattingStats.Hits, system, false, { fractionalDigits: 0}),
      ...enumKeyMapAndConvert(Enums.BattingStats.TotalBases, system, false, { fractionalDigits: 0}),
      ...enumKeyMapAndConvert(Enums.BattingStats.Strikeouts, system, false, { fractionalDigits: 0}),
      ...enumKeyMapAndConvert(Enums.BattingStats.StrikeoutRate, system, false, { fractionalDigits: 1 }),
      ...enumKeyMapAndConvert(Enums.BattingStats.Swings, system, false, { fractionalDigits: 0}),
      ...enumKeyMapAndConvert({
        ...Enums.BattingStats.Whiffs,
        short: 'Misses'
      }, system, false, { fractionalDigits: 0 }),
      ...enumKeyMapAndConvert(Enums.BattingStats.SwingRate, system, false, { fractionalDigits: 1 }),
      ...enumKeyMapAndConvert(Enums.BattingStats.WhiffRate, system, false, { fractionalDigits: 1 }),
      ...enumKeyMapAndConvert(Enums.BattingStats.ZSwingRate, system, false, { fractionalDigits: 1 }),
      ...enumKeyMapAndConvert(Enums.BattingStats.ZContactRate, system, false, { fractionalDigits: 1 }),
      ...enumKeyMapAndConvert(Enums.BattingStats.OSwingRate, system, false, { fractionalDigits: 1 }),
      ...enumKeyMapAndConvert(Enums.BattingStats.OContactRate, system, false, { fractionalDigits: 1 }),
      ...enumKeyMapAndConvert(Enums.BattingStats.HomeRuns, system, false, { fractionalDigits: 0 }),
      ...enumKeyMapAndConvert(Enums.BattingStats.BattedBalls, system, false, { fractionalDigits: 0 }),
      ...enumKeyMapAndConvert(Enums.BattingStats.GroundBallRate, system, false, { fractionalDigits: 1 }),
      ...enumKeyMapAndConvert(Enums.BattingStats.LineDriveRate, system, false, { fractionalDigits: 1 }),
      ...enumKeyMapAndConvert(Enums.BattingStats.FlyBallRate, system, false, { fractionalDigits: 1 }),
      ...enumKeyMapAndConvert(Enums.BattingStats.PopUpRate, system, false, { fractionalDigits: 1 }),
      ...enumKeyMapAndConvert(Enums.BattingStats.PitchesPerPa, system, false, { fractionalDigits: 1 }),
      ...enumKeyMapAndConvert(Enums.BattingStats.PlateAppearances, system, false, { fractionalDigits: 0 }),
  };
};

const pitchingMappers = (system) => {
  return {
    pitchType: [pitchTypeMapper, 'PitchType'],
    [Enums.PitchingStats.NumberOfPitches.key]: [justReturnMapper, Enums.PitchingStats.NumberOfPitches.short],
    ...enumKeyMapAndConvert(Enums.PitchingStats.PitchUsage, system, false, { fractionalDigits: 1}),
    ...enumKeyMapAndConvert({...Enums.PitchingStats.PitchSpeedAvg, short: 'AVG Velocity'}, system, true, { fractionalDigits: 1}),
    ...enumKeyMapAndConvert({...Enums.PitchingStats.PitchSpeedMax, short: 'MAX Velocity'}, system, true, { fractionalDigits: 1}),
    ...enumKeyMapAndConvert({...Enums.PitchingStats.PitchSpinAvg, short: 'Avg Spin'}, system, true, { fractionalDigits: 0}),
    ...enumKeyMapAndConvert({...Enums.PitchingStats.PitchMovementHAvg, short: 'AVG H Movement'}, system, true, { fractionalDigits: 1}),
    ...enumKeyMapAndConvert({...Enums.PitchingStats.PitchMovementVAvg, short: 'AVG V Movement'}, system, true, { fractionalDigits: 1}),
    ...enumKeyMapAndConvert({...Enums.PitchingStats.ExtensionAvg, short: 'AVG Extension'}, system, true, { fractionalDigits: 1}),
    ...enumKeyMapAndConvert(Enums.PitchingStats.KZoneRate, system, false, { fractionalDigits: 1}),
    ...enumKeyMapAndConvert(Enums.PitchingStats.HitExitSpeedAvg, system, true, { fractionalDigits: 1}),
    ...enumKeyMapAndConvert(Enums.PitchingStats.HitsAllowed, system, false, { fractionalDigits: 0}),
    ...enumKeyMapAndConvert(Enums.PitchingStats.Strikeouts, system, false, { fractionalDigits: 0}),
    ...enumKeyMapAndConvert({...Enums.PitchingStats.StrikeoutRate, short: "SO%"}, system, false, { fractionalDigits: 1}),
    ...enumKeyMapAndConvert(Enums.PitchingStats.Swings, system, false, { fractionalDigits: 0}),
    ...enumKeyMapAndConvert({...Enums.PitchingStats.Whiffs, short: "Misses"}, system, false, { fractionalDigits: 0}),
    ...enumKeyMapAndConvert(Enums.PitchingStats.SwingRate, system, false, { fractionalDigits: 1}),
    ...enumKeyMapAndConvert(Enums.PitchingStats.WhiffRate, system, false, { fractionalDigits: 1}),
    ...enumKeyMapAndConvert(Enums.PitchingStats.SwingingStrikeRate, system, false, { fractionalDigits: 1}),
    ...enumKeyMapAndConvert(Enums.PitchingStats.ZSwingRate, system, false, { fractionalDigits: 1}),
    ...enumKeyMapAndConvert(Enums.PitchingStats.ZContactRate, system, false, { fractionalDigits: 1}),
    ...enumKeyMapAndConvert(Enums.PitchingStats.OSwingRate, system, false, { fractionalDigits: 1}),
    ...enumKeyMapAndConvert(Enums.PitchingStats.OContactRate, system, false, { fractionalDigits: 1}),
  };
}

/**
 *
 * @param {*} row
 * @param {Array<[string, (v: any) => any, string]>} mappers
 */
const mapRow = (row, mappers) => {
  return transform(row, (acc, val, key) => {
    if (mappers[key]) {
      const [mapper, newKey] = mappers[key];
      const useKey = newKey || key;
      acc[useKey] = mapper(val);
    } else {
      acc[key] = val;
    }
  }, {});
}


/**
 *
 * @param {IPlayerPartial[]} collection
 * @param {string} system
 * @param {{ key: string }} ctx
 */
export const mapCollection = (collection, system, ctx) => {
  let mappers;
  switch(ctx.key) {
    case EReportContext.BATTING.key:
      mappers = battingMappers(system);
      return collection.map(m => mapRow(m, mappers));
    case EReportContext.PITCHING.key:
      mappers = pitchingMappers(system);
      return collection.map(m => mapRow(m, mappers));
    default:
      return collection;
  }
}

const prepareRow = (keys) => (item, playerPartial = {}) => {
  const rowPartial = pick(item, keys);

  const row = {
    ...playerPartial,
    ...rowPartial,
  };

  return row;
}

/**
 *
 * @param {*} acc
 * @param {PlayerModel|undefined} player
 * @param {PlayerBattingSummaryDto} summary
 * @param {PlayerBattingStatisticsDto[]} data
 */
const battingMapper = (acc, player, summary, data = []) => {
  if (!player) {
    return;
  }
  const playerPartial = getPlayerPartial(player, EReportContext.BATTING.key);

  const rowMapper = prepareRow(BattingRowPartialKeys);

  const toBeSorted = [];

  for (let i = 0; i < data.length; i++) {
    let item = data[i];

    const row = rowMapper(item, playerPartial);

    toBeSorted.push(row);
  }

  toBeSorted.sort((a, b) => {
    return b.NumberOfPitches - a.NumberOfPitches;
  });
  acc.push(...toBeSorted);

  const allPitchesRow = rowMapper({...summary, pitchType: 'ALL'}, playerPartial);

  acc.push(allPitchesRow);
}

/**
 *
 * @param {*} acc
 * @param {PlayerModel|undefined} player
 * @param {*} summary
 * @param {*} data
 */
const pitchingMapper = (acc, player, summary = {}, data = []) => {
  if (!player) {
    return;
  }
  const playerPartial = getPlayerPartial(player, EReportContext.PITCHING.key);

  const rowMapper = prepareRow(PitchingRowPartialKeys);

  const toBeSorted = [];

  for (let i = 0; i < data.length; i++) {
    let item = data[i];

    const row = rowMapper(item, playerPartial);

    toBeSorted.push(row);
  }

  toBeSorted.sort((a, b) => {
    return b.NumberOfPitches - a.NumberOfPitches;
  });
  acc.push(...toBeSorted);

  const allPitchesRow = rowMapper({...summary, pitchType: 'ALL'}, playerPartial);

  acc.push(allPitchesRow);
}

/**
 * @param {(id: number) => PlayerModel|undefined} playerGetter
 */
export const sessionBattingReducer = (playerGetter) => /**
* @param {*} acc
* @param {BatterDto} curr */(acc, curr) => {
  const { batterId, pitchTypes, summary } = curr;
  const player = playerGetter(Number(batterId));
  if (player) {
    battingMapper(acc,  player, summary, pitchTypes);
  }
  return acc;
}

/**
 * @param {(id: number) => PlayerModel|undefined} playerGetter
 */
export const sessionPitchingReducer = (playerGetter) => /**
* @param {*} acc
* @param {PitcherDto} curr
*/ (acc, curr) => {
  const { pitcherId, pitchTypes, summary } = curr;
  const player = playerGetter(Number(pitcherId));
  if (player) {
    pitchingMapper(acc,  player, summary, pitchTypes);
  }
  return acc;
}

/**
 *
 * @param {() => PlayerModel} playerGetter
 */
export const playerBattingReducer =  (playerGetter) => (acc, curr) => {
  const { pitchTypes, summary } = curr;
  const player = playerGetter();
  if (player) {
    battingMapper(acc,  player, summary, pitchTypes);
  }
  return acc;
}

/**
 *
 * @param {() => PlayerModel} playerGetter
 */
export const playerPitchingReducer = (playerGetter) => (acc, curr) => {
  const { pitchTypes, summary } = curr;
  const player = playerGetter();
  if (player) {
    pitchingMapper(acc,  player, summary, pitchTypes);
  }
  return acc;
}
