<template>
  <v-data-table :headers="headers" :items="items" v-bind="$props">
  <slot></slot></v-data-table>
</template>

<script>
import { defineComponent } from 'vue';

function flattenObject(obj, parentKey = '') {
    let result = {};

    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            let newKey = parentKey ? `${parentKey}.${key}` : key;

            if (typeof obj[key] === 'object' && obj[key] !== null) {
                Object.assign(result, flattenObject(obj[key], newKey));
            } else {
                result[newKey] = obj[key];
            }
        }
    }

    return result;
}

export default defineComponent({
  name: 'JsonTable',
  props: {
    value: {
      type: [Object,Array],
      default: () => [],
    },
  },

  computed: {
    headers() {
      if (this.value) {
        let obj;

        if (Array.isArray(this.value)) {
          [obj] = this.value;
        } else {
          obj = this.value;
        }

        if (obj) {
          let flattened = flattenObject(obj);
          return Object.keys(flattened).map((key) => {
            return {
              text: key,
              value: key,
            };
          })
        }
      }
      return [];
    },
    items() {
      if (this.value) {
        if (Array.isArray(this.value)) {
          return this.value.map((item) => {
            return flattenObject(item);
          });
        }
        return [flattenObject(this.value)];
      }
      return [];
    },
  },
});
</script>

<style lang="scss" scoped></style>
