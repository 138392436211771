<template>
  <div v-if="debug">
    <json-table :value="debugOutput"></json-table>

    <v-btn @click="onExportClick" v-bind="btnProps">
      {{ btnTxt }}
      <!-- <v-icon size="24">$vuetify.icons.export</v-icon> -->
    </v-btn>
  </div>
  <v-btn v-else @click="onExportClick" v-bind="btnProps">
    {{ btnTxt }}
    <!-- <v-icon size="24">$vuetify.icons.export</v-icon> -->
  </v-btn>
</template>

<script>
import { defineComponent } from 'vue';
import { baseExportCsv, sessionFilename } from '@/utils/CSVResultsExporter';
import { Providers } from '@flightscope/baseball-stats';
import { Session } from '@/models/orm/Hierarchy';
import { PlayerModel } from '@/models/PlayerModel';
import { mapCollection, playerBattingReducer, playerPitchingReducer, sessionBattingReducer, sessionPitchingReducer } from '../../../utils/csvExports';
import { EReportContext } from '../../../enums/reports';
import JsonTable from './JsonTable.vue';

const ESessionOrPlayerContext = Object.freeze({
  SESSION: 'session',
  PLAYER: 'player',
  OTHER: 'other',
});

export default defineComponent({
  name: 'ExportCsvBtn',

  components: {
    JsonTable,
  },

  props: {
    btnTxt: {
      type: String,
      default: 'Export'
    },

    context: {
      type: Object,
      required: true,
    },

    sessionOrPlayer: {
      type: [Session, PlayerModel, Object],
      required: true,
    },

    debug: {
      type: Boolean,
      default: false,
    },

    stats: {
      type: Array,
      default: () => [],
    },

    unitsSystem: {
      required: true,
    },

    report: {
      required: true
    },

    filters: {
      required: true,
    },
  },

  data(){
    return {
      loading: false,
    };
  },

  computed: {
    baseData() {
      return this.provideData();
    },

    debugOutput() {
      if (this.debug) {
        const content = this.csvContent();
        return content;
      }
      return [];
    },

    isSession() {
      return this.sessionOrPlayer instanceof Session;
    },

    isPlayer() {
      return this.sessionOrPlayer instanceof PlayerModel;
    },

    btnProps() {
      return {
        loading: this.loading,
        color: "red",
        dark: true,
        // elevation: 0,
        fixed: true,
        rounded: true,
        bottom: true,
        right: true,
      };
    },
  },

  methods: {
    provideBattingData() {
      const provider = new Providers.CumulativeBattingSummaryDataProvider();
      return provider.getDataForHittingSummary(this.report, this.filters);
    },

    providePitchingData() {
      return Providers.PitchingSummaryDataProvider(this.report, undefined, this.filters);
    },

    provideData() {
      switch(this.context.key) {
        case EReportContext.BATTING.key:
          return this.provideBattingData();
        case EReportContext.PITCHING.key:
          return this.providePitchingData();
        default:
          return [];
      }
    },

    csvContent() {
      const sessionOrPlayerCtx = this.isSession ? ESessionOrPlayerContext.SESSION : this.isPlayer ? ESessionOrPlayerContext.PLAYER : ESessionOrPlayerContext.OTHER;
      const mapped = this.mapData(this.context, sessionOrPlayerCtx, this.baseData);
      return mapped;
    },

    onExportClick() {
      this.loading = true;
      let filename = '';
      if (this.isSession) {
        filename = sessionFilename(this.sessionOrPlayer, this.unitsSystem.system);
        filename = `${filename}-${this.context.label}`;
      } else if (this.isPlayer) {
        filename = `${this.sessionOrPlayer.FormattedName}-${this.context.label}`
      }

      const csvData = baseExportCsv(this.csvContent(), filename);
      this.loading = false;
      return csvData;
    },

    /**
     *
     * @param {number} id
     * @returns {PlayerModel|undefined}
     */
    getPlayerFromStats(id) {

      /** @typedef {{ playerData: PlayerModel }} IPlayerItem */
      /** @type {IPlayerItem|undefined} */
      const result = this.stats.find(/** @param {IPlayerItem} s */(s) => s.playerData.id === id);
      if (result) {
        const { playerData } = result;
        return playerData;
      }
      return;
    },

    /**
     *
     * @returns {PlayerModel|undefined}
     */
    getPlayerFromContext() {
      if (this.isPlayer) {
        return this.sessionOrPlayer;
      }
      return;
    },

    mapData(ctx, type,  data = []) {
      const reducers = {};

      switch(type) {
        case ESessionOrPlayerContext.SESSION:
          reducers[EReportContext.BATTING.key] = sessionBattingReducer(this.getPlayerFromStats);
          reducers[EReportContext.PITCHING.key] = sessionPitchingReducer(this.getPlayerFromStats);
          break;
        case ESessionOrPlayerContext.PLAYER:
          reducers[EReportContext.BATTING.key] = playerBattingReducer(this.getPlayerFromContext);
          reducers[EReportContext.PITCHING.key] = playerPitchingReducer(this.getPlayerFromContext);
          break;
        default:
          return [];
      }
      let rows = [];

      switch(ctx.key) {
        case EReportContext.BATTING.key:
        case EReportContext.PITCHING.key:
          rows = data.reduce(reducers[ctx.key], []);
          rows = mapCollection(rows, this.unitsSystem.system, ctx);
          break;
        default:
          break;
      }
      return rows;
    },
  },
});
</script>

<style scoped></style>
