import omit from 'lodash/omit';
import values from 'lodash/values';
import { Enums } from '@flightscope/baseball-stats';
import { mapOptionsToSelect } from '@/utils/mappers';
import ResultFilter from '@/filters/ResultFilter';
import Selectable from '@/components/mixins/Selectable';
import FilterableSelector from '@/components/mixins/FilterableSelector';
import CheckboxGridDropdown from '@/components/ui/dropdowns/CheckboxGridDropdown.vue';
import { filter } from 'lodash';

export default {
  name: 'PitchResultDropdown',

  mixins: [Selectable, FilterableSelector],

  props: {
    label: {
      type: String,
      required: true,
    },

    options: {
      type: Array,
      default: () => mapOptionsToSelect(ResultFilter.PitchResult.key),
    },

    multiple: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    CheckboxGridDropdown,
  },

  computed: {
    actionGroups() {
      return values(omit(Enums.PitchResultGroup, 'all')).map(({ name, results }) => ({ text: name, items: results }));
    },

    filteredOptions() {
      if (this.options && Array.isArray(this.options)) {
        return this.options.map(this.mapper);
      }
      return [];
    },
  },

  methods: {
    mapper(item) {
      if (this.itemsPolicy === 'whitelist') {
        item.disabled = this.list.includes(item.value) ? false : true;
        return item;
      } else if (this.itemsPolicy === 'blacklist') {
        item.disabled = this.list.includes(item.value) ? true : false;
        return item;
      }
    },
  },
};
