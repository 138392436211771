<template>
  <div class="d-flex align-center">
    <v-avatar color="white" :size="size" :class="$style.teamLogoUrl">
      <v-img v-if="url" :src="url" :contain="true" :aspect-ratio="1" class="d-inline-flex">
        <template v-slot:placeholder>
          <v-skeleton-loader type="avatar" />
        </template>
      </v-img>
      <span
        v-else-if="shortCode"
        :style="{ fontSize: textFontSize }"
        class="d-inline-flex align-items-center font-weight-bold black--text"
      >
        {{ shortCode }}
      </span>
    </v-avatar>
    <span v-if="displayName &&  name" class="pl-3">{{ name }}</span>
  </div>
</template>

<script>
export default {
  name: 'TeamLogoUrl',
  props: {
    size: {
      type: Number,
      default: 60,
    },
    displayName: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: false,
    },
    url: {
      type: String,
      required: false,
    },
    shortCode: {
      type: String,
    },
  },
  computed: {
    textFontSize() {
      return `${this.size * 0.35}px`;
    },
  },
};
</script>

<style lang="scss" module>
.teamLogoUrl {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
</style>
