<template>
  <v-avatar color="white" :size="size" class="playerAvatar" :class="{ shadow }" :rounded="rounded">
    <v-img v-if="src"
      :src="src"
      :contain="true"
      :aspect-ratio="1"
      class="d-inline-flex"
      >
      <template v-slot:placeholder>
        <v-skeleton-loader :type="skeletonType" />
      </template>
    </v-img>
  </v-avatar>
</template>

<script>
export default {
  name: 'PlayerAvatar',
  props: {
    src: {
      type: String,
      default: process.env.VUE_APP_DEFAULT_PLAYER_AVATAR,
    },
    size: {
      type: [Number, String, undefined],
      default: 30,
    },
    rounded: {
      type: [Boolean, String],
    },
    skeletonType: {
      type: String,
      default: 'avatar',
    },
    shadow: {
      type: Boolean,
      default: true,
    }
  },
  model: {
    prop: ['src'],
    event: 'input',
  },
};
</script>

<style lang="scss" scoped>
.playerAvatar {
  &.shadow {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }
}
</style>
