<template>
  <v-sheet :style="style" class="text-center">
    <v-icon size="39">$vuetify.icons.infoCustom</v-icon>
    <p class="body-1 font-weight-bold mb-0 mt-6 mx-auto">{{ message }}</p>
  </v-sheet>
</template>

<script>
export default {
  name: 'NoData',
  props: {
    message: {
      type: String,
      default: 'There is no data',
    },
    padding: {
      type: String,
      default: '100px',
    },
  },
  computed: {
    style() {
      return `padding-top: ${this.padding}; padding-bottom: ${this.padding}`;
    },
  },
};
</script>

<style scoped>
p {
  max-width: 24em;
}
</style>
